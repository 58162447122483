<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-container>
            <b-row>
              <b-col cols="12">
                <qrcode-stream :track="paintBoundingBox" @decode="onDecode"></qrcode-stream>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      code: '',
    }
  },
  methods: {
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    onDecode(code) {
      alert(`Qrcode Is ${code}`);
    },
  }
}
</script>

<style scoped>
.qrcode-stream-camera {
  width: 320px;
  height: auto;
}
</style>
